<template>
  <dialogCom :options="selectionDialogOption">
    <p class="mb10">注：精选推荐店铺最多可设置6个推荐位</p>
    <el-table :data="tableData" highlight-current-row :row-style="{height:'48px'}" :cell-style="{padding:'0px'}" border
      v-loading="tabelLoading" element-loading-text="数据获取中...">
      <el-table-column prop="No" label="排序" align="center" width="60">
        <template slot-scope="scope">
          {{scope.$index+1}}
        </template>
      </el-table-column>
      <el-table-column prop="shopId" label="商户id" align="center" width="100" />
      <el-table-column label="商户名称" align="center">
        <template slot-scope="scope">
          <el-select v-model="scope.row.shopName" size="medium" filterable remote :remote-method="(val)=>getShopListData(val, 1)"
            placeholder="请选择" @change="(val)=>selectShopChange(val,scope.$index,1)" @visible-change="visibleChange"
            :loading="shopListLoading" clearable>
            <el-option v-for="item in shopDataLists" :key="item.id" :label="item.shopName" :value="item.shopId">
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="商户电话" align="center">
        <template slot-scope="scope">
          <el-select v-model="scope.row.phone" size="medium" filterable remote allow-create :remote-method="(val)=>getShopListData(val,2)"
            placeholder="请选择" @change="(val)=>selectShopChange(val,scope.$index,2)" @visible-change="visibleChange"
            :loading="shopListLoading" clearable>
            <el-option v-for="item in shopDataLists" :key="item.id" :label="item.contactTel" :value="item.shopId">
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="140">
        <template slot-scope="scope">
          <!-- deleteDataListItem(deleteOptions, scope.$index) -->
          <el-button type="text" v-if="scope.row.shopId" @click="deleteShopItem(scope.row, scope.$index)">移除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="btn-lists">
      <el-button type="info" @click="resetTabelDateList" :loading="btnLoading" :disabled="btnLoading">取消</el-button>
      <PrevReclick>
        <el-button type="primary" @click="saveRecommendShops" :loading="btnLoading || tabelLoading" :disabled="btnLoading || tabelLoading">
          保存并发布</el-button>
      </PrevReclick>
    </div>
  </dialogCom>
</template>

<script>
import { $sp_saveShopDataList, $sg_getRecommendShopList, $sg_deleteShopItem } from '@/api/recommendSettings';
import dialogCom from './dialogCompoent.vue';
import { settingMixins } from '../settingMixins';

export default {
  mixins: [settingMixins],
  components: {
    dialogCom,
  },
  data() {
    return {
      selectionDialogOption: {
        title: '精选推荐店铺列表',
        appendToBody: false,
        width: '990px',
        visible: true,
        showClose: false,
      },
      tableData: [
        {},
        {},
        {},
        {},
        {},
        {},
      ],
    };
  },
  created() {
    this.tradingAreaId = this.$parent.businessArea.tradingAreaId;
    this.getShopListData();
    this.getTabelDataList();
  },
  methods: {
    // 获取表格数据
    getTabelDataList() {
      this.tabelLoading = true;
      $sg_getRecommendShopList({ tradingAreaId: this.tradingAreaId }).then((d) => {
        for (let i = 0; i < this.tableData.length; i += 1) {
          if (d && d[i]) {
            this.$set(this.tableData, i, d[i]);
          }
        }
      }).finally(() => {
        this.tabelLoading = false;
      });
    },
    // 选择商户信息
    selectShopChange(val, index, type) {
      const {
        shopName = '',
        shopId = '',
        contactTel = '',
        hadChoosed,
      } = this.chooseShopItem(val, index) || {};
      if (shopId) {
        this.$set(this.tableData, index, {
          ...this.tableData[index],
          shopName,
          shopId,
          phone: contactTel,
        });
      } else if (!hadChoosed && !shopId && type === 2) {
        this.$set(this.tableData[index], 'phone', val);
      }
    },
    deleteShopItem(row, index) {
      if (row.id) {
        $sg_deleteShopItem({ shopId: row.shopId, areaId: this.tradingAreaId }).then(() => {
          this.$set(this.tableData, index, {});
        });
      } else {
        this.$set(this.tableData, index, {});
      }
    },
    // 保存精选推荐商户
    saveRecommendShops() {
      const arr = this.tableData.filter((item) => item.shopId);
      arr.forEach((item, index) => {
        item.areaId = this.tradingAreaId;
        item.recommend = 0;
        item.sort = index + 1;
      });
      if (arr.length < 1) {
        this.$message.error('至少设置一个推荐位');
        return;
      }
      this.btnLoading = true;
      $sp_saveShopDataList(arr).then(() => {
        this.resetTabelDateList();
      }).catch(() => {
        this.btnLoading = false;
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "../settingCss.css";
.btn-lists {
  margin-top: 30px;
}
</style>
