<template>
  <div class="recommendSetting wh" recommendSettings="true">
    <header class="setting-header"><span>校园商圈设置</span></header>
    <div class="setting-content">
      <div class="phone">
        <div class="phone-show wh">
          <img class="w100" src="@/assets/img/recommendedSetting/img01.png">
          <img class="w100" src="@/assets/img/recommendedSetting/img02.png">
          <img class="w100" src="@/assets/img/recommendedSetting/img03.png">
        </div>
      </div>
      <div class="data-table fdcb">
        <div class="data-table-lists">
          <el-table :data="tableData" height="100%" highlight-current-row :row-style="{height:'54px'}" :cell-style="{padding:'0px'}" border
            v-loading="tabelLoading" element-loading-text="数据获取中...">
            <el-table-column prop="createTime" label="添加时间" align="center" width="160" />
            <el-table-column prop="tradingAreaName" label="代理学校名称" align="center" min-width="180" show-overflow-tooltip />
            <el-table-column label="商户数量" align="center" width="100">
              <template slot-scope="scope">
                {{scope.row.shopCount || 0}}
              </template>
            </el-table-column>
            <el-table-column prop="date" label="商圈营业时间" align="center" width="160">
              <template slot-scope="scope">
                <div class="fc">
                  <!-- <span v-if="scope.row.businessTimeStart"
                    style="margin-right:6px;">{{scope.row.businessTimeStart}}-{{scope.row.businessTimeEnd}}</span> -->
                  <el-button type="text" @click="listBtnClick(scope.row, scope.$index, 0)">设置</el-button>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="300">
              <template slot-scope="scope">
                <el-button type="text" @click="listBtnClick(scope.row, scope.$index, 1)">顶部轮播图</el-button>
                <el-button type="text" @click="listBtnClick(scope.row, scope.$index, 2)">精选推荐</el-button>
                <el-button type="text" @click="listBtnClick(scope.row, scope.$index, 3)">特惠专区</el-button>
                <el-button type="text" @click="listBtnClick(scope.row, scope.$index, 4)">综合推荐</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination background layout="prev, pager, next" :total="total" @current-change="(val)=>{paginationChange(val, getDatalist)}">
        </el-pagination>
      </div>
    </div>
    <dialogCom :options="timeDialog">
      <div class="from-item fcb">
        <span class="from-item-label">请选择商圈营业时间</span>
        <div class="from-item-content">
          <el-cascader size='small' :options="optionsStartTime" v-model="startTime" clearable placeholder="开始时间" separator=":"
            class="k-el-cascader">
          </el-cascader>
          <span class="intervalWord">到</span>
          <el-cascader size='small' :options="optionsStartTime" v-model="endTime" clearable placeholder="结束时间" separator=":"
            class="k-el-cascader">
          </el-cascader>
        </div>
      </div>
      <div class="btn-lists">
        <el-button type="info" @click="closeTimeDialog" :loading="btnLoading" :disabled="btnLoading">取消</el-button>
        <PrevReclick>
          <el-button type="primary" @click="submitTime" :loading="btnLoading" :disabled="btnLoading">确定</el-button>
        </PrevReclick>
      </div>
    </dialogCom>
    <setting-banner v-if="dialogsType === 1" />
    <select-recommend v-else-if="dialogsType === 2" />
    <preferential v-else-if="dialogsType === 3" />
    <comprehensive-recommend v-else-if="dialogsType === 4" />
  </div>
</template>

<script>
import { $sg_tradingAreaSelected, $sp_setBusinessTime } from '@/api/recommendSettings';
// deepClone, getSeconds,
import { parseSeconds, getSeconds } from '@base/utils';
import settingBanner from './components/settingBanner.vue';
import selectRecommend from './components/selectionOfRecommended.vue';
import comprehensiveRecommend from './components/comprehensiveRecommend.vue';
import preferential from './components/preferential.vue';
import dialogCom from './components/dialogCompoent.vue';
import { settingMixins } from './settingMixins';

export default {
  name: 'recommendSettings',
  mixins: [settingMixins],
  components: {
    'setting-banner': settingBanner,
    'select-recommend': selectRecommend,
    'comprehensive-recommend': comprehensiveRecommend,
    preferential,
    dialogCom,
  },
  data() {
    return {
      startTime: [],
      endTime: [],
      dialogsType: null,
      optionsStartTime: [],
      timeDialog: {
        title: '商圈营业时间',
        appendToBody: false,
        width: '500px',
        visible: false,
        showClose: false,
      },
      tableData: [],
      businessArea: null,
    };
  },
  created() {
    this.optionsStartTime = this.datePickerData();
    this.getDatalist();
  },
  methods: {
    // 获取商圈数据
    getDatalist() {
      this.tabelLoading = true;
      $sg_tradingAreaSelected(this.pageParams).then((d) => {
        const { total, records } = d || {};
        this.total = total;
        this.tableData = records;
      }).finally(() => {
        this.tabelLoading = false;
      });
    },
    // 开启弹框
    listBtnClick(row, index, type) {
      this.dialogsType = type;
      this.nowIndex = index;
      this.businessArea = row;
      if (type === 0) {
        const startMinute = getSeconds(row.businessTimeStart);
        const startSeconds = startMinute % 3600;
        const endMinute = getSeconds(row.businessTimeEnd);
        const endSeconds = endMinute % 3600;
        this.startTime = row.businessTimeStart ? [startMinute - startSeconds, startSeconds] : [];
        this.endTime = row.businessTimeEnd ? [endMinute - endSeconds, endSeconds] : [];
        this.timeDialog.visible = true;
      }
    },
    closeTimeDialog() {
      this.startTime = [];
      this.endTime = [];
      this.timeDialog.visible = false;
    },
    submitTime() {
      let errInfo = '';
      if (!this.startTime.length) {
        errInfo = '请选择营业开始时间';
      }
      if (!this.endTime.length) {
        errInfo = '请选择营业结束时间';
      }
      if (errInfo) {
        this.$message.error(errInfo);
        return;
      }
      const params = {
        businessTimeStart: parseSeconds(this.startTime[0] + this.startTime[1]),
        businessTimeEnd: parseSeconds(this.endTime[0] + this.endTime[1]),
        tradingAreaId: this.businessArea.tradingAreaId,
      };
      this.btnLoading = true;
      $sp_setBusinessTime(params).then(() => {
        this.getDatalist();
        this.closeTimeDialog();
      }).finally(() => {
        this.btnLoading = false;
      });
    },
  },
};
</script>

<style  lang="scss" scope>
@import "./settingCss.css";
.recommendSetting {
  box-sizing: border-box;
  background-color: #fff !important;
  .setting-header {
    height: 66px;
    line-height: 65px;
    padding-left: 35px;
    border-bottom: 1px solid #e4e4e4;
    &::before {
      left: 20px;
      top: 23px;
    }
  }
  .setting-content {
    display: flex;
    width: 100%;
    height: calc(100% - 67px);
    box-sizing: border-box;
    padding: 35px 0;
    .phone {
      width: 27.15%;
      max-width: 448px;
      height: 100%;
      margin: 0 40px 0 40px;
      box-sizing: border-box;
      padding: 20px 22px;
      border-radius: 10px;
      border: 1px solid #e6e6e6;
      .phone-show {
        overflow-x: hidden;
        overflow-y: scroll;
        border: 2px solid #e6e6e6;
      }
    }
    .data-table {
      flex: 1;
      min-width: 100px;
      height: 100%;
      margin-right: 40px;
      .data-table-lists {
        height: calc(100% - 50px);
      }
    }
  }
}
.k-el-cascader.el-cascader {
  width: 130px;
}
.from-item {
  margin: 5px 0 40px 0;
  .intervalWord {
    margin: 0 10px;
  }
}
</style>
