<template>
  <dialogCom :options="comprehensiveDialogOption">
    <p class="mb10">注：综合推荐店铺最多可设置10个推荐位</p>
    <el-table :data="tableData" highlight-current-row :row-style="{height:'48px'}" :cell-style="{padding:'0px'}" border
      v-loading="tabelLoading" element-loading-text="数据获取中...">
      <el-table-column prop="No" label="排序" align="center" width="60">
        <template slot-scope="scope">
          {{scope.$index+1}}
        </template>
      </el-table-column>
      <el-table-column prop="shopId" label="商户id" align="center" width="100" />
      <el-table-column label="商户名称" align="center">
        <template slot-scope="scope">
          <el-select v-model="scope.row.shopName" size="medium" placeholder="请选择" filterable remote :loading="shopListLoading"
            :remote-method="(val)=>getShopListData(val, 1)" @change="(val)=>selectShopChange(val,scope.$index, 1)"
            @visible-change="visibleChange" clearable>
            <el-option v-for="item in shopDataLists" :key="item.id" :label="item.shopName" :value="item.shopId">
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="商户电话" align="center">
        <template slot-scope="scope">
          <el-select v-model="scope.row.contactTel" size="medium" placeholder="请选择" filterable remote allow-create
            :loading="shopListLoading" :remote-method="(val)=>getShopListData(val, 2)"
            @change="(val)=>selectShopChange(val,scope.$index, 2)" @visible-change="visibleChange" clearable>
            <el-option v-for="item in shopDataLists" :key="item.id" :label="item.contactTel" :value="item.shopId">
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="跳转页" align="center" width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.shopId">{{getbannerLinkWay(scope.row.recommendType)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="140">
        <template slot-scope="scope">
          <el-button type="text" v-if="scope.row.shopId" @click="deleteDataListItem(deleteOptions, scope.$index)">移除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="btn-lists">
      <el-button type="info" @click="resetTabelDateList" :loading="btnLoading" :disabled="btnLoading">取消</el-button>
      <PrevReclick>
        <el-button type="primary" @click="submitDataInfo" :loading="btnLoading || tabelLoading" :disabled="btnLoading || tabelLoading">保存并发布
        </el-button>
      </PrevReclick>
    </div>
  </dialogCom>
</template>

<script>
import { $sg_getTradingAreaLayoutShop, $sp_tradingAreaLayoutShopAdd } from '@/api/recommendSettings';
import dialogCom from './dialogCompoent.vue';
import { settingMixins } from '../settingMixins';

export default {
  mixins: [settingMixins],
  components: {
    dialogCom,
  },
  data() {
    return {
      comprehensiveDialogOption: {
        title: '综合推荐店铺列表',
        appendToBody: false,
        width: '990px',
        visible: true,
        showClose: false,
      },
      deleteOptions: {
        title: '移除提示',
        content: '确认移除该综合推荐店铺？',
      },
      tableData: [
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
      ],
    };
  },
  created() {
    this.tradingAreaId = this.$parent.businessArea.tradingAreaId;
    this.getShopListData();
    this.getComprehensiveShopDataList();
  },
  methods: {
    // 保存并发布
    submitDataInfo() {
      const arr = this.tableData.filter((item) => item.shopId);
      arr.forEach((item, index) => {
        item.tradingAreaId = this.tradingAreaId;
        item.sort = index + 1;
      });
      if (arr.length < 1) {
        this.$message.error('至少设置一个推荐位');
        return;
      }
      this.btnLoading = true;
      $sp_tradingAreaLayoutShopAdd(arr).then(() => {
        this.resetTabelDateList();
      }).catch(() => {
        this.btnLoading = false;
      });
    },
    getComprehensiveShopDataList() {
      this.tabelLoading = true;
      $sg_getTradingAreaLayoutShop({ tradingAreaId: this.tradingAreaId }).then((d) => {
        for (let i = 0; i < this.tableData.length; i += 1) {
          if (d && d[i]) {
            this.$set(this.tableData, i, d[i]);
          }
        }
      }).finally(() => {
        this.tabelLoading = false;
      });
    },
    // 选择商户信息
    selectShopChange(val, index, type) {
      const {
        shopName = '',
        shopId = '',
        contactTel = '',
        hadChoosed,
      } = this.chooseShopItem(val, index) || {};
      if (shopId) {
        this.$set(this.tableData, index, {
          ...this.tableData[index],
          recommendType: 1,
          shopName,
          shopId,
          contactTel,
        });
      } else if (!hadChoosed && !shopId && type === 2) {
        this.$set(this.tableData[index], 'contactTel', val);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../settingCss.css";
.btn-lists {
  margin-top: 30px;
}
</style>
