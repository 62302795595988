import api from './api';

// 当前代理商拥有的商圈 默认选择
export const $sg_tradingAreaSelected = (params) => api.get('/sssk/api/agent/biz/tradingArea/page', params);
// 获取商圈下的商户列表
export const $sg_tradingAreaShop = (params) => api.get('/sssk/api/agent/biz/tradingAreaShop/page', params);
// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>营业时间
// 当前代理商拥有的商圈 设置营业时间
export const $sp_setBusinessTime = (params) => api.post('/sssk/api/agent/biz/tradingArea/setBusinessTime', params);
// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 轮播图
// 轮播图设置 
export const $sp_batchSave = (params) => api.post('/sssk/api/agent/biz/tradingAreaLayoutBanner/batchSave', params);
// 轮播图列表回显
export const $sg_getBannerList = (params) => api.get('/sssk/api/agent/biz/tradingAreaLayoutBanner/list', params);
// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 精选推荐
// 保存精选店铺 
export const $sp_saveShopDataList = (params) => api.post('/sssk/api/agent/biz/selectedShop/save', params);
// 获取精选店铺 
export const $sg_getRecommendShopList = (params) => api.get('/sssk/api/agent/biz/selectedShop/queryList', params);
// 批量删除精选店铺
export const $sg_deleteShopItem = (params) => api.get('/sssk/api/agent/biz/selectedShop/delete', params);
// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 特惠专区
// 通过商圈id分页查询特惠商品
export const $sg_getSpecialGoodsData = (params) => api.get('/sssk/api/agent/biz/layOutProduct/pageList', params);
// 删除特惠专区商品判断是否还有进行中的订单 
export const $sg_goodsCanDelete = (params) => api.get('/sssk/api/agent/biz/layOutProduct/canDelete', params);
// 删除特惠专区商品
export const $sg_specialGoodsDelete = (params) => api.get('/sssk/api/agent/biz/layOutProduct/delete', params);
// 新增/编辑特惠专区商品  
export const $sg_specialGoodsAdd = (params) => api.post('/w/agent/special_goods/save', params);
// 特惠专区商品详情
export const $sg_specialGoodsInfo = (params) => api.get('/w/agent/special_goods/info', params);
// 获取店铺所有商品
export const $sg_queryGoodsByShopId = (params) => api.get('/sssk/api/agent/biz/layOutProduct/queryAllProduct', params);
// 上传背景图  
export const $sp_tradingAreaProductBackground = (params) => api.post('/sssk/api/agent/biz/tradingAreaProductBackground/add', params);
// 查看背景图
export const $sg_getTradingAreaProductBackground = (params) => api.get('/sssk/api/agent/biz/tradingAreaProductBackground/get', params);
// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 综合推荐
// 新增店铺推荐位  
export const $sp_tradingAreaLayoutShopAdd = (params) => api.post('/sssk/api/agent/biz/tradingAreaLayoutShop/add', params);
// 获取推荐位设置
export const $sg_getTradingAreaLayoutShop = (params) => api.get('/sssk/api/agent/biz/tradingAreaLayoutShop/get', params);
