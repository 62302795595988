import ChooseFile from '@base/utils/file/ChooseFile';
import FileUploader from '@base/utils/file/FileUploader';
import { $sg_tradingAreaShop } from '../../../api/recommendSettings';

export const settingMixins = {
  data() {
    return {
      firstLayerDialog: true, // 所有第一层dialog
      nowIndex: null, // 当前操作的数据
      loading: false,
      shopDataLists: [],
      tabelLoading: false,
      btnLoading: false,
      shopListLoading: false,
      pageParams: {
        pageCurrent: 1,
        pageSize: 10,
      },
      total: 0,
      searchShopList: {
        pageCurrent: 1,
        pageSize: 15, // 分页条数
        tradingAreaId: '',
        contactTel: '',
        shopName: '',
      },
      tradingAreaId: null,
      linkMethods: [
        {
          label: '外卖点餐首页',
          value: 1,
        },
        {
          label: '自定义长图',
          value: 2,
        },
        {
          label: '不跳转',
          value: 3,
        },
      ],
      isParent: false,
    };
  },
  methods: {
    // 选择商户
    chooseShopItem(val, index = null, isTable = true) {
      if (isTable && index != null) {
        const hadIndex = this.tableData.findIndex((item) => item.shopId === val);
        if (hadIndex > -1) {
          this.$message.error('该店铺已选择');
          this.$set(this.tableData, index, {});
          return { hadChoosed: true };
        }
      }
      return this.shopDataLists.find((item) => item.shopId === val);
    },
    // 分页器页码发生变化
    paginationChange(val, callback) {
      this.pageParams.pageCurrent = val;
      callback();
    },
    // 获取当前商圈下的商户
    getShopListData(val = '', type = '') {
      this.shopListLoading = true;
      this.searchShopList.shopName = type === 1 ? val : '';
      this.searchShopList.contactTel = type === 2 ? val : '';
      this.searchShopList.tradingAreaId = this.tradingAreaId;
      $sg_tradingAreaShop(this.searchShopList).then((d) => {
        this.shopDataLists = d.records;
        this.shopListLoading = false;
      });
    },
    visibleChange(val) {
      if (!val) {
        this.getShopListData();
      }
    },
    // 获取跳转方式
    getbannerLinkWay(type) {
      const itemMethod = this.linkMethods.find((item) => item.value === type);
      return itemMethod ? itemMethod.label : '—';
    },
    // 删除移除数据
    deleteDataListItem(options, index) {
      const { content, title } = options;
      this.$confirm(content, title, {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        customClass: 'k-messageBox-class warning',
      }).then(() => {
        this.$set(this.tableData, index, {});
      });
    },
    // 选择头像
    chooseHeaderImg(options) {
      const change = new ChooseFile();
      change.chooseImage(this.getFile.bind(this, options));
    },
    async getFile(options, file) {
      const { key, width = null, height = null } = options;
      const fileUploder = new FileUploader(file, { width, height, addLibrary: false });
      fileUploder.upload().then((url) => {
        this.form[key] = url;
      }).catch((error) => {
        this.$message.error(error.message || '上传失败，请重新上传');
      });
    },
    // 重置表格数据
    resetTabelDateList() {
      this.nowIndex = null;
      this.businessArea = null;
      if (this.$parent.dialogsType !== undefined) {
        this.$parent.dialogsType = null;
      }
      this.tableData = this.$options.data().tableData;
      this.btnLoading = false;
    },
    // 设置时间选择项
    datePickerData() {
      const arr = [];
      for (let i = 0; i < 24; i += 1) {
        const json = {
          label: i < 10 ? `0${i}` : i,
          value: i * 3600,
          children: [],
        };
        for (let k = 0; k < 60; k += 1) {
          json.children.push({
            label: k < 10 ? `0${k}` : k,
            value: k * 60,
          });
        }
        arr.push(json);
      }
      return arr;
    },
  },
  beforeDestroy() {
    try {
      if (this.isParent) {
        this.$msgbox.close();
      }
    } catch {
      console.log();
    }
  },
};
