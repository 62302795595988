<template>
  <dialogCom :options="bannerListDialog">
    <p class="mb10">注：首页轮播最多可设置5个广告位</p>
    <el-table :data="tableData" height="100%" highlight-current-row :row-style="{height:'54px'}" :cell-style="{padding:'0px'}" border
      v-loading="tabelLoading" element-loading-text="数据获取中...">
      <el-table-column prop="No" label="排序" align="center" width="60">
        <template slot-scope="scope">
          {{scope.$index+1}}
        </template>
      </el-table-column>
      <el-table-column label="广告图" align="center" width="180">
        <template slot-scope="scope">
          <img class="shopImg" v-if="scope.row.resourceUrl" :src="scope.row.resourceUrl">
        </template>
      </el-table-column>
      <el-table-column prop="shopId" label="商户id" align="center" width="100" />
      <el-table-column prop="shopName" label="商户名称" align="center" show-overflow-tooltip />
      <el-table-column prop="tel" label="商户电话" align="center" width="130" />
      <el-table-column label="跳转页" align="center" width="140">
        <template slot-scope="scope">
          <span v-if="scope.row.directType!=null">{{getbannerLinkWay(scope.row.directType || '')}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="140">
        <template slot-scope="scope">
          <el-button type="text" @click="editeBanner(scope.row,scope.$index)">{{scope.row.directType?'编辑':'新增'}}</el-button>
          <el-button type="text" v-if="scope.row.directType" @click="deleteDataListItem(deleteOptions, scope.$index)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="btn-lists">
      <el-button type="info" @click="resetTabelDateList" :loading="btnLoading" :disabled="btnLoading">取消</el-button>
      <PrevReclick>
        <el-button type="primary" @click="saveBannerList" :loading="btnLoading || tabelLoading" :disabled="btnLoading || tabelLoading">保存并发布
        </el-button>
      </PrevReclick>
    </div>
    <dialogCom :options="bannerInfoDialog">
      <el-form ref="form" :model="form" label-width="80px" class="banner-from">
        <div class="setBannerDialog-content fb">
          <div class="banner-img">
            <p class="setting-header"><span>广告图</span></p>
            <p class="banner-size">建议尺寸：1053*360</p>
            <div class="banner upload-img">
              <el-image class="wh" v-if="form.resourceUrl" :src="form.resourceUrl" fit="fill"></el-image>
            </div>
            <p class="banner-size" style="margin-bottom:16px;">该广告将在顶部轮播广告中第{{numbers[nowIndex]}}个播出</p>
            <el-link type="primary" :underline="false" @click="chooseHeaderImg({key:'resourceUrl'})">{{form.resourceUrl?'重新':''}}上传图片<i
                class="el-icon-arrow-right"></i>
            </el-link>
          </div>
          <div class="banner-info">
            <p class="setting-header"><span>选择落地页</span></p>
            <el-form-item class="k-from-item-noLabel">
              <el-radio-group v-model="form.directType">
                <el-radio v-for="item in linkMethods" :key="item.value" :label="item.value">{{item.label}}</el-radio>
              </el-radio-group>
            </el-form-item>
            <div class="banner-content-info">
              <el-form-item label="商户名称" v-if="form.directType===1">
                <el-select v-model="form.shopId" placeholder="请选择" filterable remote :remote-method="(val)=>getShopListData(val, 1)"
                  @change="chooseShopChange" @visible-change="visibleChange" :loading="shopListLoading" clearable>
                  <el-option v-for="item in shopDataLists" :key="item.id" :label="item.shopName" :value="item.shopId">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item class="k-from-item-noLabel" v-if="form.directType===2">
                <div class="upload-img">
                  <el-image class="wh" v-if="form.directUrl" :src="form.directUrl" fit="fill"></el-image>
                </div>
                <el-button size="mini" @click="chooseHeaderImg({key: 'directUrl'})">上传图片</el-button>
                <p class="banner-size">该长图将作为点击广告后的展示页</p>
              </el-form-item>
              <p class="banner-size" v-if="form.directType===3">该广告无落地页，不可点击</p>
            </div>
            <el-form-item class="k-from-item-noLabel">
              <div class="btn-lists">
                <el-button @click="resetBannerInfoFormData">取消</el-button>
                <el-button type="primary" @click="saveBannerInfo">确定</el-button>
              </div>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </dialogCom>
  </dialogCom>
</template>

<script>
import { $sg_getBannerList, $sp_batchSave } from '@/api/recommendSettings';
import dialogCom from './dialogCompoent.vue';
import { settingMixins } from '../settingMixins';

export default {
  name: 'banner',
  mixins: [settingMixins],
  components: {
    dialogCom,
  },
  data() {
    return {
      bannerListDialog: {
        title: '轮播图列表',
        appendToBody: false,
        width: '990px',
        visible: true,
        showClose: false,
      },
      bannerInfoDialog: {
        title: '设置轮播图',
        appendToBody: true,
        width: '990px',
        visible: false,
        showClose: false,
      },
      form: {
        directType: 1, // 跳转类型
        directUrl: '', // 跳转目的地址
        resourceUrl: '', // banner
        shopId: '', //  商户
      },
      deleteOptions: {
        title: '删除提示',
        content: '确认删除该轮播图？',
      },
      numbers: ['一', '二', '三', '四', '五'],
      tableData: [
        {},
        {},
        {},
        {},
        {},
      ],
    };
  },
  created() {
    this.tradingAreaId = this.$parent.businessArea.tradingAreaId;
    this.getBannerDataList();
  },
  methods: {
    // 获取banner列表数据
    getBannerDataList() {
      this.tabelLoading = true;
      $sg_getBannerList({ tradingAreaId: this.tradingAreaId }).then((d) => {
        for (let i = 0; i < this.tableData.length; i += 1) {
          if (d && d[i]) {
            this.$set(this.tableData, i, d[i]);
          }
        }
      }).catch(() => {
        this.resetTabelDateList();
      }).finally(() => {
        this.tabelLoading = false;
      });
    },
    // 重置banner信息数据数据
    resetBannerInfoFormData() {
      this.nowIndex = null;
      this.form = this.$options.data().form;
      this.setBannerInfoDialog(false);
    },
    // 点击编辑
    editeBanner(row, index) {
      this.nowIndex = index;
      if (row.directType) {
        this.form = { ...row };
      }
      this.getShopListData();
      this.setBannerInfoDialog(true);
    },
    // 商户选择
    chooseShopChange(val) {
      const { shopName, contactTel } = this.chooseShopItem(val) || {};
      this.form.shopName = shopName;
      this.form.tel = contactTel;
    },
    // 保存当前banner 信息
    saveBannerInfo() {
      const {
        directType,
        directUrl,
        resourceUrl,
        shopId,
      } = this.form;
      let errInfo = '';
      if (directType === 1 && !shopId) {
        errInfo = '请选择商户';
      }
      if (directType === 2 && !directUrl) {
        errInfo = '请上传广告展示页';
      }
      if (!resourceUrl) {
        errInfo = '请上传轮播图';
      }
      if (errInfo) {
        this.$message.error(errInfo);
        return;
      }
      if (directType === 1 || directType === 3) {
        this.form.directUrl = '';
      }
      if (directType === 2 || directType === 3) {
        this.form.shopId = '';
        this.form.shopName = '';
        this.form.tel = '';
      }
      this.$set(this.tableData, this.nowIndex, this.form);
      this.resetBannerInfoFormData();
    },
    // 保存banner数据列表
    saveBannerList() {
      const arr = this.tableData.filter((item) => item.directType);
      arr.forEach((item, index) => {
        item.tradingAreaId = this.tradingAreaId;
        item.sort = index + 1;
      });
      if (arr.length < 1) {
        this.$message.error('至少设置一个广告位');
        return;
      }
      this.btnLoading = true;
      $sp_batchSave(arr).then(() => {
        this.resetTabelDateList();
      }).catch(() => {
        this.btnLoading = false;
      });
    },
    setBannerInfoDialog(val) {
      this.bannerInfoDialog.visible = val;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../settingCss.css";
.shopImg {
  width: 120px;
  height: 41px;
}
.btn-lists {
  margin-top: 30px;
}
.setBannerDialog-content {
  .setting-header {
    padding-left: 10px;
    margin-bottom: 14px;
    &::before {
      top: 3px;
      left: 0;
    }
  }
  .banner-img {
    width: 350px;
    margin-left: 70px;
    .banner-size {
      font-size: 14px;
      color: #999999;
    }
    .banner {
      width: 100%;
      height: 120px;
      margin: 40px 0 20px 0;
      background: #e4e4e4;
    }
  }
  .banner-info {
    width: 400px;
    .banner-content-info {
      min-height: 260px;
      .upload-img {
        width: 150px;
        height: 200px;
      }
    }
  }
}

.banner-from /deep/ .el-form-item__label {
  text-align: left !important;
}
</style>
