<template>
  <el-dialog :title="options.title" :visible="options.visible" class="k-setRider-dialog" :close-on-click-modal="false"
    :width="options.width" :close-on-press-escape="false" :show-close="options.showClose" :append-to-body="options.appendToBody"
    @close="closeDialog" v-bind="$attrs">
    <slot></slot>
    <span slot="footer" class="dialog-footer"></span>
  </el-dialog>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    closeDialog() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
@import "../settingCss.css";
</style>
