<template>
  <dialogCom :options="preferentialDialogOption" @close="resetTabelDateList">
    <div class="add-btn-box">
      <div class="upload-bg-box">
        <el-upload action="#" :http-request="upload_bg_img" :show-file-list="false">
          <el-button type="text">添加背景图 <i class="el-icon-arrow-right"></i></el-button>
        </el-upload>
        <span class="tips">建议尺寸：750*398，仅允许上传jpg、png格式的图片</span>
        <i class="el-icon-view" @click="getBackgroundImg"></i>
      </div>
      <el-button type="primary" @click="setGoodsDialog(true)" :loading="tabelLoading" :disabled="tabelLoading">新增</el-button>
    </div>
    <el-table :data="tableData" highlight-current-row :row-style="{height:'42px'}" :cell-style="{padding:'0px'}" border
      v-loading="tabelLoading" element-loading-text="数据获取中...">
      <el-table-column prop="No" label="排序" align="center" width="60">
        <template slot-scope="scope">
          {{scope.$index+1}}
        </template>
      </el-table-column>
      <el-table-column prop="shopId" label="商户id" align="center" width="100" />
      <el-table-column prop="shopName" label="商户名称" align="center" min-width="180" show-overflow-tooltip />
      <el-table-column prop="contactTel" label="商户电话" align="center" width="130" />
      <el-table-column prop="productId" label="商品id" align="center" width="100" />
      <el-table-column prop="productName" label="商品名称" align="center" min-width="180" show-overflow-tooltip />
      <!-- <el-table-column label="跳转页" align="center" width="140">
        <template>
          商品详情
        </template>
      </el-table-column> -->
      <el-table-column label="操作" align="center" width="140">
        <template slot-scope="scope">
          <el-button type="text" @click="editGoodsItem(scope.row)">编辑</el-button>
          <el-button type="text" @click="deleteGoodsList(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="preferentialPage">
      <el-pagination background layout="prev, pager, next" :total="total"
        @current-change="(val)=>{paginationChange(val, getGoodsDataList)}">
      </el-pagination>
    </div>
    <dialogCom :options="goodsDialogOption" class="preferential-goods-dialog" top='6vh'>
      <el-form ref="form" :model="form" label-width="100px" class="preferential-goods-from banner-from">
        <div class="preferential-goods-from-box">
          <div class="preferential-goods-left">
            <el-form-item label="推荐商户">
              <el-select v-model="form.shop_id" placeholder="请输入商户名称" class="w100" filterable remote
                :remote-method="(val)=>getShopListData(val, 1)" @change="selectShopChange" @visible-change="visibleChange"
                :loading="shopListLoading" clearable :disabled="!!form.id">
                <el-option v-for="item in shopDataLists" :key="item.id" :label="item.shopName" :value="item.shopId">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="推荐特惠商品">
              <el-select v-model="form.product_id" placeholder="请输入商品名称/ID" class="w100" @change="selectGoodsChange"
                :disabled="!!(!form.shop_id || form.id)" clearable>
                <el-option v-for="item in goodsList" :key="item.productId" :label="item.productName" :value="item.productId">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="商品图片">
              <div class="upload-img">
                <img class="wh" v-if="form.product_img" :src="form.product_img">
              </div>
            </el-form-item>
            <el-form-item label="是否限购">
              <el-radio-group v-model="form.limited">
                <el-radio :label="0">不限购</el-radio>
                <el-radio :label="1">限购</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="每人限购" v-if="form.limited==1">
              <div style="display:flex;">
                <el-input v-model="form.limited_num" type="number" clearable />
                <span class="unit-span">份</span>
              </div>
            </el-form-item>
            <el-form-item label="抽佣比例">
              <div style="display:flex;">
                <el-input v-model="form.profit_percent" type="number" clearable />
                <span class="unit-span">%</span>
              </div>
            </el-form-item>
            <el-form-item label="商品售价">
              <el-input v-model="form.selling_price" type="number" clearable />
            </el-form-item>
            <el-form-item label="商品原价">
              <el-input v-model="form.original_price" type="number" clearable />
            </el-form-item>
            <el-form-item label="活动库存">
              <el-input v-model="form.product_repertory" type="number" clearable :disabled="!!form.id" />
            </el-form-item>
            <el-form-item label="活动时间">
              <div>
                <el-col :span="11">
                  <el-date-picker type="datetime" placeholder="选择日期" v-model="form.active_start_time" value-format="yyyy-MM-dd HH:mm:ss" />
                </el-col>
                <el-col class="line" :span="2">到</el-col>
                <el-col :span="11">
                  <el-date-picker type="datetime" placeholder="选择日期" v-model="form.active_end_time" value-format="yyyy-MM-dd HH:mm:ss" />
                </el-col>
              </div>
              <span class="time-description">注：此为特惠商品活动截止时间</span>
            </el-form-item>
          </div>
          <div class="preferential-goods-right">
            <div class="preferential-goods-right-title">
              <span>规格属性(选填)</span>
              <span>商品价格=基础单价+规格加价+属性加价</span>
            </div>
            <div class="preferential-goods-right-sub-title">
              <div>商品规格</div>
              <div>
                规格如：大份&nbsp;&nbsp;小份&nbsp;&nbsp;中份&nbsp;&nbsp;大杯&nbsp;&nbsp;超大杯&nbsp;&nbsp;中杯&nbsp;&nbsp;大&nbsp;&nbsp;中&nbsp;&nbsp;小
              </div>
            </div>
            <div class="specs-item" v-for="(item, index) in form.spec" :key="(index + 1) * 100">
              <el-input v-model="item.goods_spec_name" style="width: 200px" placeholder="规格名" clearable />
              <el-input 
                v-model="item.goods_spec_price" 
                prefix-icon="el-icon-plus" 
                style="width: 100px;margin: 0px 10px;" 
                placeholder="加价" 
                clearable />
              <el-button type="text" @click="delSpec(index)">删除</el-button>
            </div>
            <el-button icon="el-icon-plus" type="primary" @click="addSpec">添加规格</el-button>
            <div class="preferential-goods-right-sub-title">
              <div>商品属性</div>
              <div>规格如：先添加属性类目，再添加具体属性。如辣度分为不辣、中辣、微辣</div>
            </div>
            <div class="attribute-item" v-for="(item, index) in form.attr" :key="index">
              <div class="attribute-item-close-icon" @click="delAttr(index)">X</div>
              <div class="attribute-item-title">
                <el-input v-model="item.goods_attr_name" style="width: 200px;margin-right: 10px;" placeholder="属性名" clearable />
                <el-button type="primary" @click="addAttrName(index)">添加</el-button>
              </div>
              <div class="attribute-item-content-box">
                <div class="attribute-item-content-item" v-for="(ite, idx) in item.spec" :key="idx">
                  <div class="attribute-item-close-icon" @click="delAttrName(index, idx)">X</div>
                  <el-input v-model="ite.goods_spec_name" style="width: 130px" placeholder="属性值" />
                  <el-input v-model="ite.goods_spec_price" prefix-icon="el-icon-plus" style="width: 100px;" placeholder="加价"/>
                </div>
              </div>
            </div>
            <el-button icon="el-icon-plus" type="primary" @click="addAttr">添加属性</el-button>
          </div>
        </div>
        <el-form-item class="k-from-item-noLabel from-btns">
          <el-button type="info" @click="setGoodsDialog(false)" :loading="btnLoading" :disabled="btnLoading">取消</el-button>
          <PrevReclick>
            <el-button type="primary" @click="submitGoodsInfo" :loading="btnLoading" :disabled="btnLoading">保存并发布</el-button>
          </PrevReclick>
        </el-form-item>
      </el-form>
    </dialogCom>
    <el-dialog
      title="背景图预览"
      :visible.sync="backgroundImgDialog"
      :modal="false"
      width="800px">
      <div class="background-img-box">
        <img :src="backgroundImg" alt="">
      </div>
    </el-dialog>
  </dialogCom>
</template>

<script>
import {
  $sg_getSpecialGoodsData, $sg_goodsCanDelete, $sg_queryGoodsByShopId,
  $sg_specialGoodsDelete, $sg_specialGoodsAdd, $sg_specialGoodsInfo,
  $sp_tradingAreaProductBackground, $sg_getTradingAreaProductBackground,
} from '@/api/recommendSettings';
import FileUploader from '@base/utils/file/FileUploader';
import dialogCom from './dialogCompoent.vue';
import { settingMixins } from '../settingMixins';


export default {
  mixins: [settingMixins],
  components: {
    dialogCom,
  },
  data() {
    return {
      form: {
        shop_id: '', // 商户id
        shopName: '',
        trading_area_id: '', // 商圈id
        limited: 0, // 是否限制
        limited_num: '', // 限制数量
        original_price: '', // 商品原价
        product_id: '', // 商品id
        product_img: '', // 商品图片
        product_repertory: '', // 产品库存
        profit_percent: '', // 抽佣比例
        selling_price: '', // 商品卖价
        active_start_time: '', // 活动开始时间
        active_end_time: '', // 活动结束时间
        spec: [ // 规格
          {
            goods_spec_name: '',
            goods_spec_price: '',
          },
        ],
        attr: [ // 属性
          {
            goods_attr_name: '',
            spec: [
              {
                goods_spec_name: '',
                goods_spec_price: '',
              },
            ],
          },
        ],
      },
      errorInfo: {
        shop_id: '请选择推荐商户',
        product_id: '请选择推荐特惠商品',
        limited: '请选择是否限购',

        limited_num: '请输入每人限购份数',
        profit_percent: { err: '请正确输入抽佣比例(0~100正整数)', reg: /^\d{1,2}$|^100$/ },
        selling_price: { err: '请正确输入商品售价(须大于0且可保留两位小数)', reg: /^[0-9]+(.[0-9]{1,2})?$/ },
        original_price: { err: '请正确输入商品原价(须大于0且可保留两位小数)', reg: /^[0-9]+(.[0-9]{1,2})?$/ }, // 
        product_repertory: { err: '请正确输入活动库存(须大于0的正整数)', reg: /^[1-9]\d*$/ },

        active_start_time: '请选择活动开始时间',
        active_end_time: '请选择活动结束时间',
      },
      preferentialDialogOption: {
        title: '特惠专区商品列表',
        appendToBody: false,
        width: '1100px',
        visible: true,
        showClose: true,
      },
      goodsDialogOption: {
        title: '选择商品',
        appendToBody: true,
        width: '1200px',
        visible: false,
        showClose: false,
        top: '6vh',
      },
      goodsList: [],
      tableData: [],
      backgroundImgDialog: false,
      backgroundImg: '',
    };
  },
  created() {
    this.tradingAreaId = this.$parent.businessArea.tradingAreaId;
    this.getShopListData();
    this.getGoodsDataList();
  },
  methods: {
    // 编辑
    editGoodsItem(row) {
      const params = {
        id: row.id,
      };
      $sg_specialGoodsInfo(params).then((res) => {
        this.form = { ...res };
        this.form.profit_percent = +this.form.profit_percent;
        this.form.id = res.id;
        this.form.spec = res.spec ? res.spec : [];
        this.form.attr = res.attr ? res.attr : [];
      });
      this.getGoodsListByShopId(row.shopId, row.productId);
      this.setGoodsDialog(true);
    },
    // 商户选择切换
    selectShopChange(val) {
      const { shopId = '', shopName = '' } = this.chooseShopItem(val) || {};
      this.goodsList = [];
      this.setGoodsInfo();
      if (shopId) {
        this.getGoodsListByShopId(shopId);
      }
      this.form.shopName = shopId ? shopName : '';
    },
    // 选择商品
    selectGoodsChange(val) {
      const goodsItem = this.goodsList.find((item) => item.productId === val);
      this.setGoodsInfo(goodsItem);
    },
    // 获取商圈下的特惠商品
    getGoodsDataList() {
      const params = {
        tradingAreaId: this.tradingAreaId,
        current: this.pageParams.pageCurrent,
        pageSize: this.pageParams.pageSize,
        tag: 1,
      };
      this.tabelLoading = true;
      $sg_getSpecialGoodsData(params).then((d) => {
        const { total, records } = d || {};
        this.total = total;
        this.tableData = records;
      }).finally(() => {
        this.tabelLoading = false;
      });
    },
    // 通过shopId 获取商户下的商品
    getGoodsListByShopId(shopId, productId = '') {
      $sg_queryGoodsByShopId({ shopId, areaId: this.tradingAreaId, productId }).then((d) => {
        this.goodsList = d;
      });
    },
    setGoodsDialog(val) {
      this.goodsDialogOption.visible = val;
      if (!val) {
        this.form = this.$options.data().form;
      }
    },
    // 上传背景图
    upload_bg_img(file) {
      const arr = file.file.name.split('.');
      const fileUploder = new FileUploader(file.file, { addLibrary: false, compress: true, ext: arr[arr.length - 1] });
      fileUploder.upload().then((url) => {
        const params = {
          tradingAreaId: this.tradingAreaId,
          imgUrl: url,
        };
        $sp_tradingAreaProductBackground(params).then(() => {});
      }).catch((error) => {
        this.$message.error(error.message);
      });
    },
    // 查看背景图
    getBackgroundImg() {
      this.backgroundImgDialog = true;
      const params = {
        tradingAreaId: this.tradingAreaId,
      };
      $sg_getTradingAreaProductBackground(params).then((res) => {
        this.backgroundImg = res.imgUrl;
      });
    },
    // 删除规格
    delSpec(index) {
      this.form.spec.splice(index, 1);
    },
    // 添加规格
    addSpec() {
      this.form.spec.push({
        goods_spec_name: '',
        goods_spec_price: '',
      });
    },
    // 删除属性
    delAttr(index) {
      this.form.attr.splice(index, 1);
    },
    // 添加属性
    addAttr() {
      this.form.attr.push({
        goods_attr_name: '',
        spec: [
          {
            goods_spec_name: '',
            goods_spec_price: '',
          },
        ],
      });
    },
    // 删除属性值
    delAttrName(index, idx) {
      this.form.attr[index].spec.splice(idx, 1);
    },
    // 添加属性值
    addAttrName(index) {
      this.form.attr[index].spec.push({
        goods_spec_name: '',
        goods_spec_price: '',
      });
    },
    // 保存
    submitGoodsInfo() {
      this.form.trading_area_id = this.tradingAreaId;
      const errArr = Object.keys(this.errorInfo);
      const errItem = errArr.find((item) => {
        const errValue = this.form[item];
        if ((item === 'selling_price' || item === 'original_price') && errValue <= 0) {
          return true;
        }
        if (item === 'limited_num' && this.form.limited === 0) {
          return false;
        }
        if (typeof this.errorInfo[item] === 'string') {
          if ((errValue ?? '') === '') {
            return true;
          }
        } else if (this.errorInfo[item].reg) {
          const reg = this.errorInfo[item].reg;
          return !reg.test(errValue);
        }
        return false;
      });
      if (errItem) {
        const errInfo = this.errorInfo[errItem];
        this.$message.error(typeof errInfo === 'string' ? errInfo : errInfo.err);
        return;
      }
      if (Number(this.form.sellingPrice) > Number(this.form.originalPrice)) {
        this.$message.error('商品售价不能大于商品原价');
        return;
      }
      const startTime = new Date(this.form.activeStartTime).getTime();
      const endTime = new Date(this.form.activeEndTime).getTime();
      if (startTime > endTime) {
        this.$message.error('活动结束时间需大于开始时间');
        return;
      }
      this.btnLoading = true;
      $sg_specialGoodsAdd(this.form).then(() => {
        this.getGoodsDataList();
        this.setGoodsDialog(false);
      }).finally(() => {
        this.btnLoading = false;
      });
    },
    setGoodsInfo(data = null) {
      const goods = {
        limited: '', // 是否限制
        limited_num: '', // 限制数量
        original_price: '', // 商品原价
        product_id: '', // 商品id
        product_img: '', // 商品图片
        product_repertory: '', // 产品库存
        profit_percent: '', // 抽佣比例
        selling_price: '', // 商品卖价
        active_start_time: '', // 活动开始时间
        active_end_time: '', // 活动结束时间
      };
      if (data) {
        // const goodsKeyArr = Object.keys(goods);
        // goodsKeyArr.forEach((item) => {
        //   this.form[item] = data[item];
        // });
        this.form.product_id = data.productId;
        this.form.product_img = data.productImg;
      } else {
        this.form = { ...this.form, ...goods };
      }
      console.log(this.form);
    },
    // 移除特惠商区商品
    deleteGoodsList(row) {
      const { productId } = row;
      $sg_goodsCanDelete({ productId, tradingAreaId: this.tradingAreaId }).then((d) => {
        if (!d) {
          this.$confirm('确认删除该特惠专区商品？', '删除提示', {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            dangerouslyUseHTMLString: true,
            customClass: 'k-messageBox-class warning',
          }).then(() => {
            $sg_specialGoodsDelete({ productId, areaId: this.tradingAreaId }).then(() => {
              this.getGoodsDataList();
            });
          }).catch(() => { });
        } else {
          this.$confirm('有正在进行中的订单，请结束后再操作删除', '删除提示', {
            confirmButtonText: '确认',
            showCancelButton: false,
            dangerouslyUseHTMLString: true,
            customClass: 'k-messageBox-class warning',
          }).then(() => { });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "../settingCss.css";
.preferentialPage {
  text-align: center;
  margin-top: 30px;
}
.add-btn-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  .upload-bg-box {
    display: flex;
    align-items: center;
    .tips {
      font-size: 12px;
      color: #999;
      margin-left: 10px;
    }
    & > i {
      margin-left: 10px;
      color: #ef3f46;
      cursor: pointer;
      user-select: none;
    }
  }
}
.upload-img {
  width: 100px;
  height: 100px;
}
.preferential-goods-dialog {
  /deep/.el-dialog__body {
    padding: 30px 40px;
  }
}
.preferential-goods-from {
  .el-form-item {
    margin-bottom: 15px;
    /deep/.el-form-item__label {
      text-align: left !important;
    }
    .line {
      text-align: center;
    }
  }
  .time-description {
    font-size: 14px;
    color: #ef3f46;
  }
  .from-btns {
    /deep/.el-form-item__content {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    .el-button {
      width: 160px;
    }
  }
  .preferential-goods-from-box {
    width: 100%;
    display: flex;
    .preferential-goods-left,.preferential-goods-right {
      flex: 1;
    }
    .preferential-goods-right {
      padding-left: 40px;
      box-sizing: border-box;
      .preferential-goods-right-title {
        font-size: 14px;
        color: #444;
        font-weight: bold;
        & > span:last-child {
          color: #ef3f46;
          margin-left: 10px;
        }
      }
      .preferential-goods-right-sub-title {
        font-size: 14px;
        color: #444;
        margin-top: 20px;
        & > div:last-child {
          color: #ef3f46;
          font-size: 12px;
          margin-top: 5px;
        }
      }
      .specs-item {
        width: 100%;
        margin: 10px 0px;
      }
      .attribute-item {
        width: 100%;
        margin: 10px 0px;
        padding: 10px;
        box-sizing: border-box;
        position: relative;
        background-color: #f5f5f5;
        .attribute-item-close-icon {
          position: absolute;
          top: -8px;
          right: -2px;
          cursor: pointer;
          user-select: none;
          z-index: 999;
        }
        .attribute-item-content-box {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          .attribute-item-content-item {
            margin-top: 10px;
            margin-right: 10px;
            flex-shrink: 0;
            position: relative;
          }
        }
      }
    }
  }
}
.unit-span {
  margin-left: 10px;
}
.el-date-editor {
  width: 100%;
}
.background-img-box {
  width: 100%;
  img {
    width: 100%;
  }
}
</style>
